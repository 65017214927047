/*
 * @Author: qxy
 * @Date: 2019-06-12 14:41:28
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-04-06 16:49:27
 */
const PageType = {
    BORROW: 'borrow',
    LEND: 'lend',
    ABOUT: 'about'
};
export default PageType;
export const ScreenMobile = 769;
