var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-store-button" }, [
    _vm.videoVisible
      ? _c(
          "div",
          {
            ref: "videoModal",
            staticClass: "video-modal",
            on: {
              touchmove: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "wrapper" }, [
              _c("div", { staticClass: "video-modal-content" }, [
                _c("video", {
                  ref: "myVideo",
                  attrs: {
                    src: _vm.videoUrl,
                    controls: "",
                    preload: "",
                    "x-webkit-airplay": "true",
                    "x5-video-player-type": "h5",
                    "x5-video-orientation": "portraint",
                    "x5-playsinline": "true",
                    "webkit-playsinline": "true",
                    playsinline: "true",
                    poster: require("./img/poster.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "btn-close",
                attrs: { src: require("./img/close.png") },
                on: { click: _vm.closeVideoModal },
              }),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "video"
      ? _c("img", {
          staticClass: "video-btn",
          attrs: { src: _vm.videoActive ? _vm.videoBtnActive : _vm.videoBtn },
          on: {
            mouseenter: function ($event) {
              _vm.videoActive = true
            },
            mouseleave: function ($event) {
              _vm.videoActive = false
            },
            click: _vm.handleVideoClick,
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "google"
      ? _c("div", { staticClass: "channel-btn google-btn" }, [
          _c("img", {
            staticClass: "channel",
            attrs: { src: require("./img/google.png") },
            on: {
              mouseenter: function ($event) {
                return _vm.handleAppCodeVisible(true)
              },
              mouseleave: function ($event) {
                return _vm.handleAppCodeVisible(false)
              },
              click: _vm.handleClick,
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "apple"
      ? _c("div", { staticClass: "channel-btn apple-btn" }, [
          _c("img", {
            staticClass: "channel",
            attrs: { src: require("./img/apple.png") },
            on: {
              mouseenter: function ($event) {
                return _vm.handleAppCodeVisible(true)
              },
              mouseleave: function ($event) {
                return _vm.handleAppCodeVisible(false)
              },
              click: _vm.handleClick,
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "apple-w"
      ? _c("div", { staticClass: "channel-btn apple-w-btn" }, [
          _c("img", {
            staticClass: "channel",
            attrs: { src: require("./img/apple-w.png") },
            on: {
              mouseenter: function ($event) {
                return _vm.handleAppCodeVisible(true)
              },
              mouseleave: function ($event) {
                return _vm.handleAppCodeVisible(false)
              },
              click: _vm.handleClick,
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.codeVisible,
            expression: "codeVisible",
          },
        ],
        staticClass: "code",
        class: _vm.codePosition,
      },
      [_c("img", { attrs: { src: require("./img/download-code.svg") } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }